import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ClinicList from './ClinicList'

export default function Clinics() {
    return (
        <Routes>
            <Route path="/" element={<ClinicList />} />
        </Routes>
    )
}
