import { Modal, Spinner } from 'react-bootstrap'
import "./loadingModal.css"

const LoadingModal = ({ show, onHide }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            backdrop="static"
            keyboard={false}
            size='sm'
            dialogClassName='loading-modal'
        >
            <Modal.Body className="text-center">
                <Spinner animation="border" role="status" variant='primary'>
                </Spinner>
                {/* <p>Loading...</p> */}
            </Modal.Body>
        </Modal>
    );
};

export default LoadingModal;