import { Modal, Button } from 'react-bootstrap';
import { FaCircleCheck } from "react-icons/fa6";

const SuccessModal = ({ isModalOpen, selectedClinic, closeModel }) => {
    return (
        <Modal
            show={isModalOpen}
            onHide={closeModel}
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>
                    <FaCircleCheck className='success-icon' />
                    Success
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                The <strong>{selectedClinic?.name}</strong>'s account has been {selectedClinic?.status === 'ACTIVE' ? 'deactivated' : 'activated'} successfully!
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={closeModel}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SuccessModal;