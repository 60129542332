import { Modal, Button } from 'react-bootstrap';
import { FaCircleCheck } from "react-icons/fa6";
import "./resultModal.css"

const ResultModal = ({ isModalOpen, closeModal }) => {
    return (
        <Modal
            show={isModalOpen}
            onHide={closeModal}
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>
                    <FaCircleCheck className='success-icon' />
                    Registration Successful!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Your clinic registration has been successfully submitted. We will contact you shortly.
                Thank you for your patience!
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={closeModal}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ResultModal;