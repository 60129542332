
import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import UserContext, { getToken } from '../../../contexts/UserContext';
import Header from '../Header';
import Menu from '../Menu';
import Content from '../Content';
import Footer from '../Footer';
import Patients from '../../features/Patients';
import Appointments from '../../features/Appointments';
import Contacts from '../../features/Contacts';
import Users from '../../features/Users';
import Settings from '../../features/Settings';
import Calendar from '../../features/Calendar';
import Spinner from '../../controls/Spinner';
import GenieFile from "../../features/genieFiles";
import Doctors from "../../features/Doctors";
import Hospitals from "../../features/Hospitals";
import Radiologies from "../../features/Radiologies";
import Pathologies from "../../features/Pathologies";
import Resources from "../../features/Resources";
import ChangePassword from '../../features/Auth/ChangePassword';
import Clinics from '../../features/Clinics';
import ClinicDashboard from '../../features/RootDashboard/DashboardInfo';

export default function MainPage() {

    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const [token, setToken] = useState();
    const { state } = useContext(UserContext);

    useEffect(() => {
        var t = userCtx.getToken();
        if (t === null)
            navigate("/signin");
        setToken(t);
    }, []);

    return (
        <div>
            {token ?
                <div>
                    <Header />
                    <Menu />
                    <Content>
                        <Routes >
                            <Route path='/patients/*' element={<Patients />} />
                            <Route path='/appointments/*' element={<Appointments />} />
                            <Route path='/contacts/*' element={<Contacts />} />
                            <Route path='/users/*' element={<Users />} />
                            <Route path='/settings/*' element={<Settings />} />
                            <Route path='/importGenieReports/*' element={<GenieFile />} />
                            <Route path='/doctors/*' element={<Doctors />} />
                            <Route path='/radiologies/*' element={<Radiologies />} />
                            <Route path='/hospitals/*' element={<Hospitals />} />
                            <Route path='/pathologies/*' element={<Pathologies />} />
                            <Route path='/resources/*' element={<Resources />} />
                            <Route path="/change-password" element={<ChangePassword />} />
                            <Route path="/clinics/*" element={<Clinics />} />

                            <Route path='/*' element={state.role === "ROOT" ? <ClinicDashboard /> : <Calendar />} />
                            <Route path='/' element={state.role === "ROOT" ? <ClinicDashboard /> : <Calendar />} />

                        </Routes>
                    </Content>
                    <Footer />
                </div> : <Spinner />
            }
        </div>
    );
}
