import './switch-toggle.css'
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/UserContext';

export default function DataTable(props) {
    //console.log('DataTable: ', props);
    const { module = 'table', id = 'id', list, view, remove, view2, view3, buttonSize = 12 } = props;
    //console.log('DataTable.List', list);


    const userCtx = useContext(UserContext);
    const [user, setUser] = useState();

    useEffect(() => {
        setUser(userCtx.getUser());
    }, []);

    return (
        <table className="table table-bordered table-hover" key={`table-${module}`}>
            <thead className="thead-light">
                <tr >
                    {
                        list.labels &&
                        list.labels.map((t) => (<th scope="col" key={t}>{t}</th>))
                    }
                    {view && view.label && <th scope="col" key={view.label}>{view.label}</th>}
                    {view2 && view2.label && <th scope="col" key={view2.label}>{view2.label}</th>}
                    {remove && <th scope="col" key={remove.label}>{remove.label}</th>}
                    {view3 && view3.label && <th scope="col" key={view3.label}>{view3.label}</th>}
                </tr>
            </thead>
            <tbody>
                {
                    list && list.data &&
                    list.data.map((el, index) => (
                        < tr key={`${module}-row-${el[id] || index}`}>
                            {
                                list.columns.map((col, colIndex) => (
                                    el && col && el[col]
                                        ? <td key={`${module}-row${el[id] || index}-col-${col || colIndex}`}>{el[col] || ""}</td>
                                        : <td key={`${module}-empty-${index}-${colIndex}`}></td>
                                ))       
                            }
                            {/* Toggle Button
                            <td key={`${module}-toggle-${el[id]}`} className="text-center">
                                <button
                                    type="button"
                                    className={`btn ${el.status === 'ACTIVE' ? 'btn-success' : 'btn-warning'}`}
                                    onClick={() => props.onToggle(el)}
                                >
                                    {el.status === 'ACTIVE' ? 'SWITCH ON' : 'Activate'}
                                </button>
                            </td> */}
                            {/* Add Toggle Button */}
                            {user && user.role === "ROOT" && (
                                <td key={`${module}-toggle-${el[id]}`} className="text-center">
                                <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={el.status === 'ACTIVE'} // Controlled by status
                                    onChange={() => props.onToggle(el)} // Trigger onToggle function
                                />
                                <span className="slider round"></span>
                                </label>
                            </td>
                            )}


                            {view && view.buttons && (
                                <td key={`${module}-swcol-${el[id]}`} className={`col-${view.colSize}`}>
                                    <div className="row">
                                        {view.buttons.map((b) => (
                                            <div className={`col-${view.buttonSize}`} key={`${module}-divbtn-${el[id]}-${b.label}`}>
                                                <button
                                                    type="button"
                                                    key={`${module}-vwbtn-${el[id]}-${b.label}1234`}
                                                    className="btn bg-gradient-secondary btn-block "
                                                    onClick={() => { b.func(el); }}>
                                                    {b.label}
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </td>)}
                            {view2 && view2.buttons && (
                                <td key={`${module}-swcol-${el[id]}`} className={`col-${view2.colSize}`}>
                                    <div className="row">
                                        {view2.buttons.map((b) => (
                                            <div className={`col-${view2.buttonSize}`} key={`${module}-divbtn-${el[id]}-${b.label}`}>
                                                <button

                                                    type="button"
                                                    key={`${module}-vwbtn-${el[id]}-${b.label}1234`}
                                                    className="btn bg-gradient-secondary btn-block "
                                                    onClick={() => { b.func(el); }}>
                                                    {el.status == "Active" ? (
                                                        "De-Activate"
                                                    ) : (
                                                        "Activate"
                                                    )}
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </td>)}
                            {remove && (
                                <td key={`${module}-rmcol-${el[id]}`} className=" col-1">
                                    <button key={`${module}-rmbtn-${el[id]}`} className="btn bg-gradient-danger btn-block" onClick={() => { remove.func(el); }}> {remove.label}</button>
                                </td>)}
                            {/* {props.removeLink && <td className=" col-1"><Link to={`${props.detailsLink}/${el[id]}/delete`}><span className="text-danger">Remove</span> </Link></td>}
                                    {props.detailsLink && <td className=" col-2"><Link to={`${props.detailsLink}/${el[id]}`}> Show</Link></td>} */}
                        </tr>
                    ))}
            </tbody>
            {/* <DataTablePagination style="algin: right" data={props.data} /> */}
        </table>
    );

}
