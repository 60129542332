import React, { useState } from 'react'

export default function SearchBox(props) {
    const { findText, text = "" } = props;

    const handle = (event) => {
        findText(event.target.value);
        // className="form-inline"  data-widget="sidebar-search"
    }

    return (
        <div>
            <div className="input-group">
                <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" onChange={handle} defaultValue={text}></input>
                <div className="input-group-append">
                    <button className="btn btn-sidebar" >
                        <i className="fas fa-search fa-fw" />
                    </button>
                </div>
            </div>
        </div>
    )
}


// import React from 'react';
// import { Form, FormControl, InputGroup } from 'react-bootstrap';
// import { FaMagnifyingGlass } from "react-icons/fa6";
// import "./SearchBox.css"

// export default function SearchBox(props) {
//     const { findText, text = "" } = props;

//     const handle = (event) => {
//         findText(event.target.value);
//     };

//     return (
//         <div >
//             <Form className="d-flex">
//                 <InputGroup>
//                     {/* Search Input */}
//                     <FormControl
//                         type="search"
//                         placeholder="Search"
//                         aria-label="Search"
//                         onChange={handle}
//                         defaultValue={text}
//                         // className="form-control-sidebar"
//                     />
//                     {/* Search Button */}
//                     {/* <Button variant="outline-secondary">
//                         <i className="fas fa-search fa-fw" />
//                     </Button> */}
//                     <FaMagnifyingGlass className='search-glass' />
//                 </InputGroup>
//             </Form>
//         </div>
//     );
// }
