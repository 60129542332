import axios from 'axios';

const dev = (process.env.NODE_ENV === 'development');
const API_URL = "https://dashboard.mymedicalsecretary.com.au/api";
const LOCAL_URL = "http://localhost:8001/api";

// set for development
export const url = dev ? LOCAL_URL : API_URL

if (dev) {
    console.log("Current URL: " + url);
}

const instance = axios.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const fileAxios = axios.create({
    baseURL: url,
    headers: {
        'content-type': 'multipart/form-data'
    }
});

export function setToken(token) {
    delete instance.defaults.headers.common["Authorization"];
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export default instance;
