import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../my-axios'
import SignIn from '../SignIn';
import ForgetPassword from '../ForgetPassword';
import ResetPassword from '../ResetRassword';
import SignUp from '../SignUp'; 

export default function AuthPage({ comp = 'SignIn' }) {
    const navigate = useNavigate();
    const { token } = useParams();

    useEffect(() => {
        console.log("AuthPage", comp, token);

    }, []);


    return (
        <div className='d-flex flex-column flex-root '>
            <div
                className='login login-1 login-signin-on d-flex flex-column flex-lg-row bg-white'
                id='kt_login'
            >{/*begin::Aside flex-column-fluid*/}
                <div
                    className='d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 col-lg-4 '
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl('/dist/img/bg.jpg')})`,
                    }}
                >
                    {/*begin: Aside Container*/}
                    <div className='d-flex flex-row-fluid flex-column justify-content-between' style={{ marginLeft: '20px', marginRight: '20px' }}>
                        {/* start:: Aside header */}
                        <div className='flex-column-auto mt-4 pb-lg-0 pb-10'>


                            <a href='https://www.mymedicalsecretary.com.au/' target='_blank' rel='noopener noreferrer'>
                                <img
                                    alt='Logo'
                                    className='
                                brand-image img-circle elevation-3 bg-white'
                                    style={{ width: '100px', height: '100px', textAlign: 'center' }}
                                    src={toAbsoluteUrl('/dist/img/logo192.png')}
                                    sizes='(max-width: 600px) 200px, 50vw'
                                />
                            </a>
                        </div>
                        {/* end:: Aside header */}

                        {/* start:: Aside content */}
                        <div className='flex-column-fluid d-flex flex-column justify-content-center'>

                            <h3 className='font-size-h3 mb-8 text-white'>
                                Welcome to My Medical Secretary!
                            </h3>
                            <p className='font-weight-lighter text-white opacity-90'>
                                This web application is designed for medical clinics to enable them to present clear and concise information to their patients and their families in an efficient and effective manner.
                            </p>
                        </div>
                        {/* end:: Aside content */}

                        <div className='d-none flex-column-auto d-lg-flex justify-content-between'>
                            <div className='opacity-70 font-weight text-white'>
                                <a className='opacity-70 font-weight text-white' href='https://www.mymedicalsecretary.com.au/' target='_blank' rel='noopener noreferrer'>&copy; MyMedicalSecretary</a>
                            </div>
                            <div className='d-flex'>
                                <a href='https://doc-hosting.flycricket.io/my-medical-secretary-privacy-policy/f11a8074-cd84-409e-9cbf-4a89f4563b88/privacy' target='_blank' rel='noopener noreferrer' className='text-white'>
                                    Privacy<p> </p>
                                </a>

                                <div style={{ marginLeft: '10px' }}></div>
                                <a href='https://www.mymedicalsecretary.com.au/contact.html' target='_blank' rel='noopener noreferrer' className='text-white ml-10'>
                                    Contact
                                </a>
                            </div>
                        </div>
                        {/* end:: Aside footer for desktop */}
                    </div>
                    {/*end: Aside Container*/}
                </div>
                {/*begin::Aside  flex-column*/}

                {/* {comp === 'ForgetPassword'
                    ? <ForgetPassword />
                    : comp === 'ResetPassword'
                        ? <ResetPassword token={token} />
                        : <SignIn />} */}
                {
                    comp === 'ForgetPassword' ? (
                        <ForgetPassword />
                    ) : comp === 'ResetPassword' ? (
                        <ResetPassword token={token} />
                    ) : comp === 'SignUp' ? (  
                        <SignUp />
                    ) : (
                        <SignIn />
                    )
                }

            </div>
        </div >
    )
}



