import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../../../my-axios';
import UserContext from '../../../../contexts/UserContext';
import MsgBox from '../../../controls/MsgBox';
import { Button, Col, Form, Row } from 'react-bootstrap';
import './signup.css';
import ResultModal from '../../../controls/ResultModal';

const URL = 'login/register-clinic'; // API endpoint for clinic sign-up
const STATES = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

const InputField = ({ controlId, label, type, value, onChange, placeholder, feedback, required = true, width = "12" }) => (
    <Form.Group as={Col} md={width} controlId={controlId}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            required={required}
        />
        <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    </Form.Group>
);

export default function SignUp() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext); // Get user context

    const [formData, setFormData] = useState({
        clinicName: '',
        clinicEmail: '',
        phone: '',
        street: '',
        suburb: '',
        state: '',
        status: '',
        password: '',
        firstname: '',
        lastname: '',
        postcode: '',
    });

    const [error, setError] = useState(null);
    const [msg, setMsg] = useState(null);
    const [validated, setValidated] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCloseModal = () => {
        navigate('/');
        setIsModalOpen(false);
    }

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
    };

    const handleAxiosError = (error) => {
        const responseMessage = error.response?.data?.message || 'An unexpected error occurred.';
        setError(`Registration failed: ${responseMessage} Please check your details and try again.`);
        setMsg(null);
    };

    const isValidForm = (form) => {
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }
        return true;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!isValidForm(form)) return;

        axios
            .post(URL, formData)
            .then((result) => {
                // setMsg(result.data.message);
                setIsModalOpen(true);
                setError(null);
            })
            .catch(handleAxiosError);
    };

    const cancel = () => navigate('/signin');

    return (
        <div className="d-flex flex-row-fluid login-page position-relative p-7 overflow-hidden col-lg-8 signup-card-width">
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                <div className="hold-transition">
                    <div
                        className="login-box signup-card"
                        style={{ overflowY: 'auto', maxHeight: '100vh', width: '150%', marginLeft: '-50px' }}
                    >
                        <div className="card card-outline card-primary">
                            <div className="card-header text-center">
                                <h2>Sign Up</h2>
                            </div>
                            <div className="card-body">
                                {error && <MsgBox value={{ type: 'error', title: '', msg: error }} />}
                                {/* {msg && <MsgBox value={{ type: 'success', title: '', msg }} />} */}
                                {!msg && (
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row className="mb-3">
                                            <InputField
                                                controlId="clinicName"
                                                label="Name of Medical Institution"
                                                type="text"
                                                placeholder="Enter institution name"
                                                value={formData.clinicName}
                                                onChange={handleInputChange}
                                                feedback="Please provide a valid name."
                                            />
                                        </Row>
                                        <Row className="mb-3">
                                            <InputField
                                                controlId="street"
                                                label="Street"
                                                type="text"
                                                placeholder="Enter street"
                                                value={formData.street}
                                                onChange={handleInputChange}
                                                feedback="Please provide a valid street."
                                                width='5'
                                            />
                                            <InputField
                                                controlId="suburb"
                                                label="Suburb"
                                                type="text"
                                                placeholder="Enter suburb"
                                                value={formData.suburb}
                                                onChange={handleInputChange}
                                                feedback="Please provide a valid suburb."
                                                width='3'
                                            />
                                            <Form.Group as={Col} md="2" controlId="state">
                                                <Form.Label>State</Form.Label>
                                                <Form.Select
                                                    id="state"
                                                    value={formData.state}
                                                    onChange={handleInputChange}
                                                    required
                                                >
                                                    <option value="" disabled>
                                                        Select
                                                    </option>
                                                    {STATES.map((state) => (
                                                        <option key={state} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid state.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <InputField
                                                controlId="postcode"
                                                label="Postcode"
                                                type="text"
                                                placeholder="Enter here"
                                                value={formData.postcode}
                                                onChange={handleInputChange}
                                                feedback="Please provide a valid postcode."
                                                width='2'
                                            />
                                        </Row>
                                        <Row className="mb-3">
                                            <InputField
                                                controlId="firstname"
                                                label="Your First Name"
                                                type="text"
                                                placeholder="Enter first name"
                                                value={formData.firstname}
                                                onChange={handleInputChange}
                                                feedback="Please provide your first name."
                                                width='6'
                                            />
                                            <InputField
                                                controlId="lastname"
                                                label="Your Last Name"
                                                type="text"
                                                placeholder="Enter last name"
                                                value={formData.lastname}
                                                onChange={handleInputChange}
                                                feedback="Please provide your last name."
                                                width='6'
                                            />
                                        </Row>
                                        <Row className="mb-3">
                                            <InputField
                                                controlId="clinicEmail"
                                                label="Email"
                                                type="email"
                                                placeholder="Enter email"
                                                value={formData.clinicEmail}
                                                onChange={handleInputChange}
                                                feedback="Please provide a valid email."
                                                width='6'
                                            />
                                            <InputField
                                                controlId="phone"
                                                label="Phone"
                                                type="tel"
                                                placeholder="Enter phone number"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                feedback="Please provide a valid phone number."
                                                width='6'
                                            />
                                        </Row>
                                        <Row className="mb-3">
                                            <InputField
                                                controlId="password"
                                                label="Password"
                                                type="password"
                                                placeholder="Enter password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                feedback="Please provide a password."
                                            />
                                        </Row>
                                        <div className="d-flex justify-content-end">
                                            <Button variant="secondary" onClick={cancel}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" type="submit" className="ml-2">
                                                Sign Up
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ResultModal isModalOpen={isModalOpen} closeModal={handleCloseModal} />
        </div>
    );
}
