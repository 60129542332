import React from 'react';
import { Pagination } from 'react-bootstrap';

export default function DataTablePagination(props) {
    //console.log("Pagination", props);
    const { page = 1, size = 10, total = 0, setPage, setSize } = props;
    let items = [];
    let len = Math.ceil(total / size);

    if (page > 1) {
        items.push(<Pagination.Prev key="prev" onClick={() => setPage(page - 1)} />);
    }
    // for (let i = 1; i == 1 ? i < len : i <= len; i++) {
    for (let i = 1; i <= len; i++) {
        items.push(
            <Pagination.Item key={i} data-page={i} active={i === page} activeLabel='' onClick={() => setPage(i)}>
                {i}
            </Pagination.Item>
        );
    }

    if (page < len) {
        items.push(<Pagination.Next key="next" onClick={() => setPage(page + 1)} />)
    }

    return (
        <Pagination >
            {items}
        </Pagination>
    )
}
