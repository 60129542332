import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import axios from '../../../../my-axios';
import { Card, Button, Row, Col } from "react-bootstrap";
import "./dashboardInfo.css";
import { FaHourglassHalf, FaUser, FaCheck, FaChartColumn, FaLocationDot, FaPhone, FaEnvelope, FaRegCalendar, FaX } from "react-icons/fa6";
import { Fa0, Fa1, Fa2, Fa3, Fa4, Fa5, Fa6, Fa7, Fa8, Fa9 } from "react-icons/fa6";
import LoadingModal from '../../../controls/LoadingModal';
import ContentContext from '../../../../contexts/ContentContext';
import UserContext from '../../../../contexts/UserContext';
import ActivationModal from '../../../controls/ActivationModel';
import SuccessModal from '../../../controls/SuccessModal';

const API_URL = "clinics";
const API_URL_STAT = "clinic-stat";
const API_URL_NEW = "clinic-new";

const iconMap = {
  '0': <Fa0 style={{ color: "#003366" }} />, '1': <Fa1 style={{ color: "#003366" }} />, '2': <Fa2 style={{ color: "#003366" }} />,
  '3': <Fa3 style={{ color: "#003366" }} />, '4': <Fa4 style={{ color: "#003366" }} />, '5': <Fa5 style={{ color: "#003366" }} />,
  '6': <Fa6 style={{ color: "#003366" }} />, '7': <Fa7 style={{ color: "#003366" }} />, '8': <Fa8 style={{ color: "#003366" }} />,
  '9': <Fa9 style={{ color: "#003366" }} />
};
const getFaIcon = (number) => iconMap[number] || iconMap['0'];

const NumberIcons = React.memo(({ number }) => {
  const numberString = number.toString();
  return (
    <div style={{ display: 'flex' }}>
      {numberString.split('').map((digit, index) => (
        <div key={index} style={{ marginRight: '-5px', fontSize: "22px" }}>
          {getFaIcon(digit)}
        </div>
      ))}
    </div>
  );
});

const DashboardSummary = ({ total, active, pending, inactive }) => (
  <Row className="mb-2" style={{ marginTop: "20px", marginBottom: "-10px" }}>
    <Col xs={3} className="mb-3">
      <Card className="dashboard-statcard">
        <Card.Body>
          <Card.Title className="dashboard-statcard-title">Total
            <FaChartColumn className="dashboard-statcard-icon" size={20} color="#2196f3" />
          </Card.Title>
          <div className="dashboard-statcard-text">
            <NumberIcons number={total} />
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={3}>
      <Card className="dashboard-statcard">
        <Card.Body>
          <Card.Title className="dashboard-statcard-title">Pending
            <FaHourglassHalf className="dashboard-statcard-icon" size={20} color="#f9a825" />
          </Card.Title>
          <div className="dashboard-statcard-text">
            <NumberIcons number={pending} />
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={3} className="mb-3">
      <Card className="dashboard-statcard">
        <Card.Body>
          <Card.Title className="dashboard-statcard-title ">Active
            <FaCheck className="dashboard-statcard-icon" size={20} color="#4caf50" />
          </Card.Title>
          <div className="dashboard-statcard-text">
            <NumberIcons number={active} />
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={3} className="mb-3">
      <Card className="dashboard-statcard">
        <Card.Body>
          <Card.Title className="dashboard-statcard-title ">Inactive
            <FaX className="dashboard-statcard-icon" size={20} color="#9e9e9e" />
          </Card.Title>
          <div className="dashboard-statcard-text">
            <NumberIcons number={inactive} />
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

const ClinicCard = React.memo(({ clinic, handleClick }) => (
  <Card.Body className="card-padding">
    <div className="d-flex justify-content-between align-items-center">
      <div>
        <Card.Title className="dashboard-appcard-title">{clinic.name}</Card.Title>
        <span className="dashboard-appcard-date badge rounded-pill">
          <span className="dashboard-appcard-date-detail">
            <FaRegCalendar className="dashboard-appcard-icon" />
            {clinic.date}
          </span>
        </span>
      </div>
      <Button
        className="dashboard-activate-btn"
        variant="outline-success"
        size="lg"
        onClick={() => handleClick(clinic)}
      >
        Activate
      </Button>
    </div>
    <Card.Text className="dashboard-appcard-text">
      <FaUser className="dashboard-appcard-icon" />
      {clinic.firstname} {clinic.surname}
    </Card.Text>
    <Card.Text className="dashboard-appcard-text">
      <FaLocationDot className="dashboard-appcard-icon" />
      {clinic.address}
    </Card.Text>
    <Card.Text className="dashboard-appcard-text">
      <FaEnvelope className="dashboard-appcard-icon" />
      {clinic.email}
    </Card.Text>
    <Card.Text className="dashboard-appcard-text">
      <FaPhone className="dashboard-appcard-icon" />
      {clinic.phone}
    </Card.Text>
    <hr />
  </Card.Body>
));

const ClinicList = React.memo(({ clinics, handleClick }) => (
  <Card className="dashboard-appcard" border="light" style={{ marginTop: "5px" }}>
    <Card.Header className="dashboard-appcard-header">New Applications</Card.Header>
    <div>
      {clinics.map((clinic) => (
        <ClinicCard key={clinic.cid} clinic={clinic} handleClick={handleClick} />
      ))}
    </div>
  </Card>
));

const ClinicDashboard = () => {
  const contentCtx = useContext(ContentContext);
  const userCtx = useContext(UserContext);
  const [clinicData, setClinicData] = useState([]);
  const [clinicStats, setClinicStats] = useState({ total: '', active: '', pending: '', inactive: '' });
  const [loading, setLoading] = useState(false);
  const [successModelOpen, setSuccessModelOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState(null);

  const onToggle = useCallback((clinic) => {
    setSelectedClinic(clinic);
    setIsModalOpen(true);
  }, []);

  const cancelToggle = useCallback(() => {
    setIsModalOpen(false);
    setSelectedClinic(null);
  }, []);

  // Function to cancel the toggle action
  const cancelSuccessModel = useCallback(() => {
    setSuccessModelOpen(false); // Close modal
    setSelectedClinic(null); // Reset selected clinic
  }, []);

  const confirmToggle = useCallback(() => {
    if (!selectedClinic) return;

    setLoading(true);
    const newStatus = selectedClinic.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

    axios
      .patch(
        `${API_URL}/${selectedClinic.cid}/status`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${userCtx.getToken()}` } }
      )
      .then(() => {
        setIsModalOpen(false);
        setSuccessModelOpen(true);
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        setSelectedClinic(null);
      })
      .finally(() => {
        setLoading(false);
        fetchClinicsData();
      });
  }, [selectedClinic, userCtx]);

  useEffect(() => {
    contentCtx.updatePath([{ title: '', link: '/' }]);
    fetchClinicsData();
  }, []);


  const fetchClinicsData = useCallback(async () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
    try {
      setLoading(true);
      const [result_stat, result_new] = await Promise.all([
        axios.get(API_URL_STAT),
        axios.get(API_URL_NEW),
      ]);
      setClinicData(formatData(result_new.data.data));
      setClinicStats(result_stat.data.stats);
    } catch (error) {
      console.error("Error fetching clinic data:", error);
    } finally {
      setLoading(false);
    }
  }, [userCtx]);

  const formatData = useMemo(() => (data) => {
    return data.map((clinic) => ({
      ...clinic,
      address: `${clinic.street}, ${clinic.suburb}, ${clinic.state}, ${clinic.postcode}`,
      date: formatDate(clinic.create_date),
    }));
  }, []);

  const formatDate = useMemo(() => (datetime) => {
    const date = new Date(datetime);
    return new Intl.DateTimeFormat('en-AU', { timeZone: 'UTC', day: '2-digit', month: 'short', year: 'numeric' }).format(date);
  }, []);

  return (
    <div className="root-dashboard">
      <DashboardSummary total={clinicStats.total} active={clinicStats.active} pending={clinicStats.pending} inactive={clinicStats.inactive} />
      <ClinicList clinics={clinicData} handleClick={onToggle} />
      <ActivationModal isModalOpen={isModalOpen} selectedClinic={selectedClinic} cancelToggle={cancelToggle} confirmToggle={confirmToggle} />
      <LoadingModal show={loading && !isModalOpen} />
      <SuccessModal isModalOpen={successModelOpen} selectedClinic={selectedClinic} closeModel={cancelSuccessModel} />
    </div>
  );
};

export default ClinicDashboard;
